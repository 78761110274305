<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-select clearable v-model="operatorId" placeholder="请选择代理商">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.operatorNm"
            :value="item.operatorId"
          ></el-option>
        </el-select>
        <el-input
          maxlength="10"
          style="width:260px;margin-left:20px;"
          placeholder="搜索规则名称"
          v-model="search"
        >
          <el-button
            slot="suffix"
            @click="searchData"
            type="primary"
            icon="el-icon-search"
            style="border-radius:0 4px 4px 0;margin-right:-5px"
            >搜索</el-button
          >
        </el-input>
        <el-button
          @click="addHandler"
          type="primary"
          icon="el-icon-plus"
          style="margin-left:20px;"
          >新增</el-button
        >
      </div>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="changeName"
          label="规则名称"
          align="center"
          min-width="120"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <!--                <el-table-column prop="ruleTypeName" align="center" label="规则类型" min-width="80"></el-table-column>-->
        <el-table-column
          prop="realName"
          label="代理商姓名"
          align="center"
          min-width="100"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="isSysGive"
          :formatter="isSysGive"
          label="平台默认"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="isGive"
          :formatter="isGive"
          label="代理商默认"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="createtime"
          label="创建时间"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="280">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button type="success" @click="editHandler(scope.row)"
                >修改规则</el-button
              >
              <!--                        <el-link @click="price(scope.row)">配置价格</el-link>&nbsp;&nbsp;&nbsp;-->
              <el-button type="primary" @click="copyHandler(scope.row)"
                >复制规则</el-button
              >

              <el-button
                type="danger"
                v-if="scope.row.isSysGive != 1 && !isOperator"
                @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog title="复制规则" :visible.sync="dialogVisibleCopy" width="40%">
      <el-form
        ref="form"
        :model="formDataCopy"
        label-width="150px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="规则名称" prop="changeName">
          <el-input
            maxlength="20"
            v-model="formDataCopy.changeName"
            style="width:300px;"
            placeholder="请输入规则名称"
            clearable
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="运营商" prop="userID">
          <el-select
            clearable
            v-model="formDataCopy.userId"
            placeholder="运营商"
          >
            <el-option
              v-for="(item, index) in agentData"
              :key="index"
              :label="item.operatorNm"
              :value="item.operatorId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleCopy = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="copyConfimHandler">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="新增运营商计费规则"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :before-close="onBeforeClose"
      v-dialogDrag
    >
      <el-form
        ref="ruleFormRef"
        :model="formData"
        label-width="150px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="规则名称" prop="changeName">
          <el-input
            maxlength="20"
            v-model="formData.changeName"
            style="width:425px;"
            placeholder="请输入规则名称"
            clearable
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="费用设置">
          <div class="tipmincc">
            （峰、平、谷时段价格均为必填项。因各地电价不同，请根据当地实际电价填写。）
          </div>
          <el-row
            v-for="(item, index) in priceTypeList"
            :key="index"
            style="margin-bottom: 5px;"
          >
            <el-tag
              :style="
                'margin-right: 20px;color: #fff;background-color: ' +
                  item.cor +
                  ';border-color:' +
                  item.cor
              "
              >{{ item.txt }}</el-tag
            >
            <el-input-number
              v-model="item.price"
              controls-position="right"
              :min="0.1"
              :max="10"
              :step="0.0001"
              :precision="2"
              style="width:120px;margin-right: 10px;"
            ></el-input-number
            ><label>电费（元/度）</label>
            <el-input-number
              v-model="item.servicePrice"
              controls-position="right"
              :min="0.1"
              :max="10"
              :step="0.1"
              :precision="2"
              style="width:120px;margin-right: 10px;"
            ></el-input-number
            ><label>服务费（元/度）</label>
          </el-row>
        </el-form-item>
        <el-form-item class="nav-form-item" label="24小时分布设置">
          <div class="tipmincc">
            （时间设置最小间隔为30分钟；最多可设置48个计费时段。）
          </div>
          <el-row>
            <el-col
              v-for="(item, index) in formData.priceList"
              :key="index"
              style="margin-bottom: 4px;"
            >
              <el-select
                v-model="item.priceType"
                placeholder="费用类型"
                style="width: 100px;"
              >
                <el-option
                  v-for="type in priceTypeList"
                  :key="type.priceType"
                  :label="type.txt"
                  :value="type.priceType"
                ></el-option>
              </el-select>
              <el-time-select
                :class="item.classStartTime"
                placeholder="开始时间"
                v-model="item.startTime"
                :picker-options="pickerOptions"
                disabled
                style="width: 120px;"
              ></el-time-select>
              <el-time-select
                :class="item.classEndTime"
                placeholder="结束时间"
                v-model="item.endTime"
                :picker-options="{
                  start: index > 0 ? addTime30(item.startTime) : '00:30',
                  step: '00:30',
                  end: '23:30',
                  minTime: item.classStartTime,
                }"
                :clearable="false"
                @change="cgEndTime(item, index)"
                :disabled="index == formData.priceList.length - 1"
                style="width: 120px;"
              ></el-time-select>
              <el-button
                type="primary"
                icon="el-icon-plus"
                style="margin-left:20px;"
                v-if="
                  (index != formData.priceList.length - 1 &&
                    item.endTime != '23:30') ||
                    index == 0
                "
                @click="plusPrice(index)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-minus"
                style="margin-left:20px;"
                v-if="index != 0 && index != formData.priceList.length - 1"
                @click="minusPrice(index)"
              ></el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            onBeforeClose(() => {
              dialogVisible = false;
            })
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRuleList,
  addRule,
  editRule,
  deleteRule,
  copyRule,
  getRulePriceList,
} from "@/api/rule";
import { getCodesByParent } from "@/api/common";
import { getDataScopeList } from "@/api/user";
import { getToken } from "@/utils/auth";
import { getOperatorSelect } from "@/api/operator";
import { getName } from "@/utils/auth";

export default {
  data() {
    return {
      pickerOptions: { start: "00:30", step: "00:30", end: "23:30" },
      dialogVisible: false,
      dialogVisibleCopy: false,
      operatorId: "",
      date: "",
      style: "",
      isAdd: true,
      search: "",
      tableData: [],
      loading: true,
      chargeTypeList: [],
      ruleTypeList: [],
      priceTypeList: [
        {
          id: "",
          priceType: 0,
          price: undefined,
          servicePrice: undefined,
          txt: "尖时段",
          cor: "#f56c6c",
        },
        {
          id: "",
          priceType: 1,
          price: "",
          servicePrice: 0.8,
          txt: "峰时段",
          cor: "#e6a23c",
        },
        {
          id: "",
          priceType: 2,
          price: 0.7,
          servicePrice: 0.8,
          txt: "平时段",
          cor: "#67c23a",
        },
        {
          id: "",
          priceType: 3,
          price: 0.39,
          servicePrice: 0.8,
          txt: "谷时段",
          cor: "#909399",
        },
      ],
      priceList: [
        {
          price: 0.3946,
          priceType: 3,
          servicePrice: 0.8,
          startTime: "00:00",
          endTime: "08:00",
          classStartTime: "",
          classEndTime: "",
        },
        {
          price: 0.695,
          priceType: 2,
          servicePrice: 0.8,
          startTime: "08:00",
          endTime: "10:00",
          classStartTime: "",
          classEndTime: "",
        },
        {
          price: 1.0044,
          priceType: 1,
          servicePrice: 0.8,
          startTime: "10:00",
          endTime: "13:00",
          classStartTime: "",
          classEndTime: "",
        },
        {
          price: 1.0044,
          priceType: 0,
          servicePrice: 0.8,
          startTime: "13:00",
          endTime: "24:00",
          classStartTime: "",
          classEndTime: "",
        },
      ],
      formData: {
        cardNo: "",
        mobile: "",
        sum: 0,
        chargeType: "",
        ruleType: "",
        changeName: "",
        isGive: "",
        isSysGive: "",
        userId: "",
        priceList: [],
      },
      formDataCopy: {
        id: "",
        changeName: "",
        userId: "",
      },
      total: 0,
      page: 1,
      pageSize: 10,
      options: [],
      formRule: {
        changeName: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
      },
      agentData: [],
    };
  },
  created() {
    this.getDataList();
    this.getDataScopeList();
    this.getCodesByParent();
    this.getRuleTypeList();
    this.getAgentList();
  },
  computed: {
    isOperator() {
      try {
        let name = JSON.parse(getName());
        return name.userInfo.isOperator === 1;
      } catch (error) {}
    },
  },
  methods: {
    editHandler(row) {
      this.isAdd = false;
      this.formData.changeName = row.changeName;
      this.formData.id = row.id;
      this.formData.priceList = [];
      this.priceTypeList.forEach((item, i) => {
        this.priceTypeList[i].id = "";
      });

      getRulePriceList({ id: row.id }).then((res) => {
        if (res.data && res.data.length > 0) {
          res.data.map((item) => {
            if (item.rangTime) {
              //还原价格
              this.priceTypeList[item.priceType].id = item.id;
              this.priceTypeList[item.priceType].price = item.price * 1;
              this.priceTypeList[item.priceType].servicePrice =
                item.servicePrice * 1;

              var rangTime = JSON.parse(item.rangTime);
              rangTime.map((it) => {
                //时间
                this.formData.priceList.push({
                  priceType: item.priceType,
                  startTime: this.getTime(it.startTime),
                  endTime: this.getTime(it.endTime),
                  classStartTime: "",
                  classEndTime: "",
                });
              });
            }
          });
          console.log("最后数据:" + JSON.stringify(this.formData.priceList));
          var list = this.formData.priceList;
          list.sort(this.sortByTime);
          console.log("排序后的数据:" + JSON.stringify(list));
          this.formData.priceList = list;
        }
      });

      this.dialogVisible = true;
    },
    sortByTime(a, b) {
      return a.startTime.split(":")[0] - b.startTime.split(":")[0];
    },
    addTime30(strTime) {
      if (strTime.indexOf(":00") != -1) {
        return strTime.replace(":00", ":30");
      } else {
        return strTime
          .toString()
          .padStart(5, "0")
          .replace(":30", ":00");
      }
    },
    getTime(num) {
      let timeStr = String(num);

      // 直接使用三元运算符判断并转换
      timeStr = timeStr.includes(".")
        ? timeStr.endsWith(".5")
          ? timeStr.slice(0, -2) + ":30"
          : timeStr + ":00"
        : timeStr + ":00";

      // 确保时间格式为两位数，不足则前补0
      return timeStr.toString().padStart(5, "0");
    },

    cgEndTime(item, index) {
      console.log("🚀 ~ file: list.vue:520 ~ this.formData:", this.formData);

      this.formData.priceList[index + 1].startTime = this.formData.priceList[
        index
      ].endTime;
      for (var i = index; i < this.formData.priceList.length; i++) {
        var startTime =
          this.formData.priceList[index + 1].startTime.replace(":", ".") * 1;
        var endTime =
          this.formData.priceList[index + 1].endTime.replace(":", ".") * 1;
        if (startTime >= endTime) {
          this.formData.priceList.splice(index + 1, 1);
          i--;
          this.formData.priceList[
            index + 1
          ].startTime = this.formData.priceList[index].endTime;
        }
      }
    },
    plusPrice(index) {
      if (this.formData.priceList.length >= 48) {
        this.$message.error("最多只能设置48个时间段哦");
        return;
      }
      let item = JSON.parse(JSON.stringify(this.formData.priceList[index]));
      item.startTime = this.formData.priceList[index].endTime;
      this.formData.priceList.splice(index + 1, 0, item);
      if (item.startTime == "23:00") {
        item.endTime = "23:30";
        this.formData.priceList[index + 1].startTime = "23:00";
        if (index + 1 < this.formData.priceList.length) {
          this.formData.priceList[index + 2].startTime = "23:30";
        }
      }
    },
    minusPrice(index) {
      this.formData.priceList[index - 1].endTime = this.formData.priceList[
        index
      ].endTime;
      this.formData.priceList.splice(index, 1);
    },
    addHandler() {
      this.isAdd = true;
      this.dialogVisible = true;
      //0:尖 1:峰 2:平 3:谷"
      this.formData = {
        changeName: "",
        priceList: [...this.priceList],
      };
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    isGive(row) {
      if (row.isGive == 1) {
        return "默认";
      } else {
        return "否";
      }
    },
    userNm(row) {
      if (row.userNm) {
        return row.userNm;
      } else {
        return "-";
      }
    },
    isSysGive(row) {
      if (row.isSysGive == 1) {
        return "默认";
      } else {
        return "否";
      }
    },
    price(row) {
      this.$router.push({
        path: "/rules/price",
        query: {
          id: row.id,
        },
      });
    },
    getRuleTypeList() {
      let param = {
        parentCode: 10,
      };
      getCodesByParent(param).then((res) => {
        if (res.data && res.data.length > 0) {
          this.ruleTypeList = [...res.data];
        }
      });
    },
    getAgentList() {
      getOperatorSelect().then((res) => {
        this.agentData = [...res.data];
      });
    },
    getCodesByParent() {
      let param = {
        parentCode: 3,
      };
      getCodesByParent(param).then((res) => {
        if (res.data && res.data.length > 0) {
          this.chargeTypeList = [...res.data];
        }
      });
    },
    getDataScopeList() {
      getDataScopeList().then((res) => {
        if (res.data && res.data.length > 0) {
          this.options = [...res.data];
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        userId: this.operatorId,
        ruleNm: this.search,
        deviceType: 4,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getRuleList(param).then((res) => {
        if (res.data.records) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    copyConfimHandler() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          this.copyRule();
        }
      });
    },
    confimHandler() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          var c = 0;
          var op = true;
          //严重时间段是否连续
          this.formData.priceList.forEach((item, index) => {
            var startTime =
              item.startTime.replace(":", ".").replace("30", "5") * 1;
            var endTime = item.endTime.replace(":", ".").replace("30", "5") * 1;

            if (index < this.formData.priceList.length - 1) {
              var startTime2 = this.formData.priceList[index + 1].startTime
                .replace(":", ".")
                .replace("30", "5");
              var endTime2 = this.formData.priceList[index + 1].endTime
                .replace(":", ".")
                .replace("30", "5");

              //时间相同校验
              if (endTime == startTime) {
                this.formData.priceList[index].classEndTime = "error_input";
                this.formData.priceList[index].classStartTime = "error_input";
                this.$message.error("开始时间和截止时间不能相同");
                throw new Error("开始时间和截止时间不能相同");
                op = false;
              }
              //开始时间小于结束时间
              if (endTime < startTime) {
                this.formData.priceList[index].classEndTime = "error_input";
                this.formData.priceList[index].classStartTime = "error_input";
                this.$message.error("开始时间不能小于结束时间");
                throw new Error("开始时间不能小于结束时间");
                op = false;
              }

              //时间连续校验
              if (endTime != startTime2) {
                this.formData.priceList[index].classEndTime = "error_input";
                this.formData.priceList[index + 1].classStartTime =
                  "error_input";
                this.$message.error("时间必须连续");
                throw new Error("时间必须连续");
                op = false;
              }
              if (op) {
                this.formData.priceList[index].classEndTime = "";
                this.formData.priceList[index + 1].classStartTime = "";
              }
            }
            c = c + (endTime - startTime);
          });
          if (op) {
            if (c == 24) {
              if (this.isAdd) {
                this.addRule();
              } else {
                this.editRule();
              }
            } else {
              this.$message.error("时间必须连续");
            }
          }
        }
      });
    },
    copyHandler(row) {
      this.isAdd = false;
      this.formDataCopy.changeName = row.changeName;
      this.formDataCopy.id = row.id;
      this.dialogVisibleCopy = true;
    },

    delete(row) {
      if (row.pileCount && row.pileCount > 0) {
        this.$message.error("规则使用中，不可删除");
        return;
      }
      deleteRule(row)
        .then((res) => {})
        .catch((err) => {});
    },

    copyRule() {
      copyRule(this.formDataCopy).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "复制成功",
            type: "success",
          });
          this.dialogVisibleCopy = false;
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
      });
    },
    editRule() {
      var priceList = new Array();

      //0:尖 1:峰 2:平 3:谷
      var priceListObj = {};
      for (var i = 0; i < 4; i++) {
        priceListObj["timeRangeList" + i] = {
          id: this.priceTypeList[i].id,
          priceType: i,
          price: this.priceTypeList[i].price,
          servicePrice: this.priceTypeList[i].servicePrice,
          timeRangeList: [],
        };
      }
      this.formData.priceList.forEach((item) => {
        var startTime = item.startTime.replace(":", ".").replace("30", "5") * 1;
        var endTime = item.endTime.replace(":", ".").replace("30", "5") * 1;
        priceListObj["timeRangeList" + item.priceType].timeRangeList.push({
          startTime: startTime,
          endTime: endTime,
        });
      });
      for (var i = 0; i < 4; i++) {
        priceList.push(priceListObj["timeRangeList" + i]);
      }
      var params = {
        id: this.formData.id,
        userId: this.operatorId,
        changeName: this.formData.changeName.replace("公众号-", ""),
        priceList: priceList,
      };
      console.log(params);
      editRule(params).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
      });
    },
    addRule() {
      var priceList = new Array();

      //0:尖 1:峰 2:平 3:谷
      var priceListObj = {};
      for (var i = 0; i < 4; i++) {
        priceListObj["timeRangeList" + i] = {
          priceType: i,
          price: this.priceTypeList[i].price,
          servicePrice: this.priceTypeList[i].servicePrice,
          timeRangeList: [],
        };
      }
      this.formData.priceList.forEach((item) => {
        var startTime = item.startTime.replace(":", ".").replace("30", "5") * 1;
        var endTime = item.endTime.replace(":", ".").replace("30", "5") * 1;
        priceListObj["timeRangeList" + item.priceType].timeRangeList.push({
          startTime: startTime,
          endTime: endTime,
        });
      });
      for (var i = 0; i < 4; i++) {
        priceList.push(priceListObj["timeRangeList" + i]);
      }
      var params = {
        userId: this.operatorId,
        changeName: this.formData.changeName,
        priceList: priceList,
      };
      console.log(params);
      addRule(params).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
      });
    },
    deteteHandlet(row) {
      if (row.pileCount && row.pileCount > 0) {
        this.$message.error("当前规则正在使用中，不可删除");
        return;
      }
      if (row.isGive == "1" || row.isSysGive == "1") {
        this.$message.error("系统默认规则，不可删除");
        return;
      }
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        deleteRule(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.message.message);
          }
        });
      });
    },
    onBeforeClose(close) {
      this.$refs.ruleFormRef.resetFields();
      this.priceTypeList = [
        {
          id: "",
          priceType: 0,
          price: undefined,
          servicePrice: undefined,
          txt: "尖时段",
          cor: "#f56c6c",
        },
        {
          id: "",
          priceType: 1,
          price: 1.0044,
          servicePrice: 0.8,
          txt: "峰时段",
          cor: "#e6a23c",
        },
        {
          id: "",
          priceType: 2,
          price: 0.695,
          servicePrice: 0.8,
          txt: "平时段",
          cor: "#67c23a",
        },
        {
          id: "",
          priceType: 3,
          price: 0.3946,
          servicePrice: 0.8,
          txt: "谷时段",
          cor: "#909399",
        },
      ];

      // this.priceList = [
      //   {
      //     price: 0.3946,
      //     priceType: 3,
      //     servicePrice: 0.8,
      //     startTime: "00:00",
      //     endTime: "08:00",
      //     classStartTime: "",
      //     classEndTime: "",
      //   },
      //   {
      //     price: 0.695,
      //     priceType: 2,
      //     servicePrice: 0.8,
      //     startTime: "08:00",
      //     endTime: "10:00",
      //     classStartTime: "",
      //     classEndTime: "",
      //   },
      //   {
      //     price: 1.0044,
      //     priceType: 1,
      //     servicePrice: 0.8,
      //     startTime: "10:00",
      //     endTime: "13:00",
      //     classStartTime: "",
      //     classEndTime: "",
      //   },
      //   {
      //     price: 1.0044,
      //     priceType: 0,
      //     servicePrice: 0.8,
      //     startTime: "13:00",
      //     endTime: "24:00",
      //     classStartTime: "",
      //     classEndTime: "",
      //   },
      // ];
      close();
    },
  },
};
</script>
<style scoped>
.error_input {
  border: 1px solid #f56c6c;
  border-radius: 4px;
}

.tipmincc {
  color: #3b99ed;
  font-size: 12px;
}
</style>
